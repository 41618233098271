import { render, staticRenderFns } from "./UploadFilesTemp.vue?vue&type=template&id=5ff95ffd&scoped=true"
import script from "./UploadFilesTemp.vue?vue&type=script&lang=js"
export * from "./UploadFilesTemp.vue?vue&type=script&lang=js"
import style0 from "./UploadFilesTemp.vue?vue&type=style&index=0&id=5ff95ffd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff95ffd",
  null
  
)

export default component.exports